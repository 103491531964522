<template>
  <!-- 课程详情页 -->
  <div>
    <div class="course-details">
      <div class="course-details-title" @click="$router.go(-1)">
        课程详情
      </div>

      <div class="course-details-content">
        <div class="course-details-content-img">
          <img style="width: 100%;height: 100%;" :src="this.subData.smallCover" alt="">
          <div class="title">{{subData.courseName}}
          </div>
        </div>

        <div class="course-details-content-details">
          <div class="title">{{subData.courseName}}
          </div>
          <div class="progress">
            <div class="progress-study">学习进度：</div>
            <div class="progressbar">
              <el-progress :percentage="subData.progress" color="#1371E7"></el-progress>
            </div>
          </div>
          <!-- <div class="code" style="">平时成绩：{{subData.progress}}</div> -->
          <div class="type">
            <div style="display: flex; justify-content: space-between;margin-bottom: 15px;">
              <div>
                考试类型：无考试

              </div>
              <div style="text-align: right;padding-right: 40px;font-size: 20px;">
              <!-- <span v-if="this.subData.progress>60" style="color: green;">
                合格
              </span>
              <span v-if="this.subData.progress<60" style="color: red;">
                不合格
              </span> -->
            </div>
            </div> 


          </div>
          <div class="do" style="">
            <div class="do-course" style="cursor: pointer;" @click="toSubject()">进入课程
            </div>
            
          </div>

          <img v-if="this.subData.progress<60" style="position: absolute;width: 75px;height: 75px;right: 0;top: 0.3125rem" src="@/assets/img/pre/img-bad.png">
          <img v-if="this.subData.progress>60" style="position: absolute;width: 75px;height: 75px;right: 0;top: 0.3125rem" src="@/assets/img/pre/img-good.png">
        </div>
      </div>

      <div class="course-details-grade">
        <div>
          <div class="title">平时成绩</div>
          <div class="grade">{{subData.progress}}</div>
        </div>
        <div class="line"></div>
        <div>
          <div class="title">考试成绩</div>
          <div class="grade">{{subData.progress}}</div>
        </div>
        <div class="line"></div>
        <div>
          <div class="title">综合成绩</div>
          <div class="grade">{{subData.progress}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet, httpNPGet,httpPost} from "@/utils/httpRequest";

export default {
  name: "CourseDetails",
  data() {
    return {
      subData: {

      },
      courseId:""
    }
  },
  mounted() {
    // console.log(this.$route.query.studyId)
    this.getCourseDetailsMsg();
  },
  methods: {
    // 获取课程详情
    getCourseDetailsMsg() {
      httpGet("/app/app-user-course-study/detail",
        {
          userId: localStorage.getItem('userId'),
          studyId: this.$route.query.studyId
        },
        (res) => {
          this.courseId = res.data.courseId
          // console.log(res.data)
          this.subData = res.data
        },
        (err) => {
          if(err.code==500){
            if(err.msg=="token异常，请重新登录"){
              localStorage.clear();
              if(!localStorage.getItem("userId")){
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }
            }
          }
          // // console.log(err)
          throw err
        })
    },
    // 进入课程视屏播放页面
    toSubject(){
      // console.log(this.$route.query.studyId)
      this.$router.push({
        path: "/videocatalog",
        query: {
          courseId: this.courseId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.course-details {
  padding: 0 3.38624%;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(3, 27, 78, 0.06);
  border-radius: 2px 2px 2px 2px;
  height: 49.5rem;

  .course-details-grade {
    width: 100%;
    height: 6rem;
    background: #F5F8FA;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 1.25rem;

    .line {
      width: 1px;
      height: 2rem;
      background: #D9D9D9;
    }

    .title {
      height: 1.5rem;
      font-size: 0.75rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
      line-height: 1.5rem;
      z-index:100;
    }

    .grade {
      height: 1.5rem;
      font-size: 1.5rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 1.5rem;
    }
  }

  .course-details-content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    position: relative;

    .course-details-content-img {
      width: 31.91%;
      height: 9.6875rem;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .title {
        line-height: 1.25rem;
        position: absolute;
        top: 40%;
        left: 15%;
        width: 71.42857%;
        font-size: 1.0625rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .course-details-content-details {
      width: 63.59788%;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
      text-align: left;

      .title {
        font-size: 1.5rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #3E454D;
        margin-bottom: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .progress {
        margin-bottom: 0.375rem;

        .progress-study {
          display: inline-block;
          margin-bottom: 0.375rem;
        }

        .progressbar {
          width: 51.09489%;
          display: inline-block;
        }
      }

      .code {
        margin-bottom: 0.375rem;
      }

      .do {
        display: flex;
        justify-content: flex-end;

        .do-course {
          font-size: 0.8125rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          height: 2rem;
          background: #1371E7;
          box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
          border-radius: 10px;
          line-height: 2rem;
          padding: 0 2.857%;
          text-align: center;
          min-width: 80px;
        }
      }
    }
  }

  /* 当前状态颜色 */
  ::v-deep .el-progress-bar__outer {
    background: rgba(0, 0, 0, 0.23);
  }

  ::v-deep .el-progress-bar {
    height: 0.75rem;
  }

  ::v-deep .el-progress__text {
    display: inline-block;
    vertical-align: middle;
    height: 1.5rem;
    font-size: 0.8125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E454D;
    margin-left: 0.625rem;
    line-height: 1;
  }

  .course-details-title {
    cursor: pointer;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 1.25rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
  }

  .course-details-title::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/icon-arrowL-24.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }
}
</style>